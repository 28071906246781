<template>
  <with-header page-title="Login" :show-back-button="false">
    <div class="w-full md:w-8/12 mx-auto px-5 md:px-0 mt-8">
      <alert :flash="$page.props.flash" class="w-full" />
      <alert :key="componentKey" :flash="alertMessage" @message-cleared="alertMessage = null"></alert>
      <Login @login="login($event)" @forgotten-password="forgottenPassword($event)" />
    </div>
  </with-header>
</template>

<script>
import WithHeader from "../../layouts/WithHeader.vue"
import Alert from "../../components/Alert.vue"
import BengieInput from "@common/components/forms/BengieInput.vue"
import BengieInputCheckbox from "@common/components/forms/BengieInputCheckbox.vue"
import Login from "../../components/Login.vue"

export default {
  name: "Index",
  components: {
    BengieInputCheckbox,
    BengieInput,
    Alert,
    WithHeader,
    Login,
  },
  props: {
    previous: {
      type: String,
    },
  },
  data() {
    return {
      alertMessage: false,
      componentKey: 1,
      forgotPassword: false,
      forgotForm: {
        data: {
          email: null,
        },
        errors: {
          email: null,
        },
      },
      form: {
        data: {
          email: null,
          password: null,
          remember: false,
        },
        errors: {
          email: null,
          password: null,
          remember: false,
        },
      },
    }
  },
  methods: {
    async login(callback) {
      try {
        const response = await callback()
        if (response?.data?.success) {
          this.updateCustomerInStore(response.data.customer)
          this.identify(response.data.customer, [this.trackingServices.customerio])
          if (response.data.redirect_url) {
            this.$inertia.get(response.data.redirect_url)
          } else if (this.previous) {
            this.$inertia.get(this.previous)
          } else {
            window.history.back()
          }
        }
        response?.resolve()
      } catch (r) {
        this.alertMessage = {
          type: "error",
          message: r.response?.data?.message,
        }
        this.componentKey++
      }
    },
    async forgottenPassword(callback) {
      try {
        const response = await callback()
        if (response?.data?.success) {
          this.forgotForm.data.email = null
          this.alertMessage = {
            type: "success",
            message: response?.data?.message,
          }
          this.componentKey++
        }
        response?.resolve()
      } catch (r) {
        this.alertMessage = {
          type: "error",
          message: r.response?.data?.message,
        }
        this.componentKey++
      }
    },
  },
}
</script>

<template>
  <div>
    <div v-if="!forgotPassword">
      <form
        @submit.prevent="submitLogin"
        class="border border-f-tertiary hj:border-f-white bg-f-white hj:bg-f-tertiary p-4 rounded-lg b-drop-shadow hj:b-drop-shadow-primary">
        <h3
          class="bo:font-graphik-semibold hj:font-druk-heavy-italic text-f-bg hj:text-f-white text-lg hj:text-xl mb-4">
          Sign in
        </h3>
        <BengieInput
          :key="componentKey"
          type="email"
          autocomplete="email"
          placeholder="Email address"
          v-model="form.data.email"
          :error="form.errors.email"
          :required="true"
          class="w-full mb-4" />
        <div class="flex flex-col md:flex-row items-start justify-start mb-4">
          <BengieInput
            :key="componentKey"
            type="password"
            autocomplete="password"
            placeholder="Password"
            v-model="form.data.password"
            :error="form.errors.password"
            :required="true"
            class="grow mr-3 mb-4 md:mb-0 w-full md:w-auto" />
          <button
            type="submit"
            class="bo:font-graphik-medium hj:font-druk-bold bg-f-contrast hj:text-2xl font-bold hj:font-normal rounded-full hj:rounded-xl text-f-tertiary hj:text-f-white py-3 md:w-auto px-5">
            Sign in
          </button>
        </div>
        <div class="flex items-start justify-between mb-4">
          <div>
            <BengieInputCheckbox v-model="form.data.remember" label="Remember me" name="remember" />
          </div>
          <p
            class="bo:font-graphik-medium hj:font-druk-bold text-gray-700 hj:text-f-white underline text-right cursor-pointer"
            @click="forgotPassword = true">
            Forgotten my password
          </p>
        </div>
      </form>
    </div>
    <div v-else>
      <form
        @submit.prevent="submitForgottenPassword"
        class="border border-f-tertiary hj:border-white bg-f-white hj:bg-f-tertiary p-4 rounded-lg b-drop-shadow hj:b-drop-shadow-primary">
        <h3
          class="bo:font-graphik-semibold hj:font-druk-heavy-italic text-f-bg hj:text-f-white text-lg hj:text-xl mb-4">
          Forgotten password
        </h3>
        <div class="flex flex-col md:flex-row items-start justify-start mb-4">
          <div class="grow mr-3 w-full md:w-auto mb-4 md:mb-0">
            <BengieInput
              :key="componentKey"
              type="email"
              autocomplete="email"
              v-model="forgotForm.data.email"
              placeholder="Email address"
              :error="forgotForm.errors.email"
              :required="true" />
          </div>
          <button
            type="submit"
            class="bo:font-graphik-medium hj:font-druk-bold hj:text-2xl bg-f-contrast font-bold hj:font-normal rounded-full hj:rounded-xl text-f-tertiary hj:text-f-white py-3 w-auto md:w-2/12 px-5">
            Request reset
          </button>
        </div>
        <div class="flex items-start justify-end mb-4">
          <p
            class="bo:font-graphik-medium hj:font-druk-bold text-gray-700 hj:text-f-white underline text-right cursor-pointer"
            @click="forgotPassword = false">
            Sign in
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import BengieInputCheckbox from "@common/components/forms/BengieInputCheckbox.vue"
import BengieInput from "@common/components/forms/BengieInput.vue"

export default {
  name: "Login",
  components: {
    BengieInput,
    BengieInputCheckbox,
  },
  emits: ["login", "forgottenPassword"],
  data() {
    return {
      componentKey: 1,
      forgotPassword: false,
      forgotForm: {
        data: {
          email: null,
        },
        errors: {
          email: null,
        },
      },
      form: {
        data: {
          email: null,
          password: null,
          remember: false,
        },
        errors: {
          email: null,
          password: null,
          remember: false,
        },
      },
    }
  },
  methods: {
    submitLogin() {
      return new Promise((resolve, reject) => {
        this.$emit("login", async () => {
          try {
            const { data } = await axios.post(route(this.getRoute("account.login.store")), this.form.data)
            return {
              data,
              resolve,
            }
          } catch (r) {
            if (r.response.data.errors) {
              if ("email" in r.response.data.errors) {
                this.form.errors.email = r.response.data.errors.email[0]
              }
              if ("password" in r.response.data.errors) {
                this.form.errors.password = r.response.data.errors.password[0]
              }
            }
            this.componentKey++
            reject(r)
          }
        })
      })
    },
    submitForgottenPassword() {
      return new Promise((resolve, reject) => {
        this.$emit("forgottenPassword", async () => {
          try {
            const { data } = await axios.post(route(this.getRoute("account.password.reset.request")), {
              email: this.forgotForm.data.email,
            })
            return {
              data,
              resolve,
            }
          } catch (r) {
            if (r.response.data.errors) {
              if ("email" in r.response.data.errors) {
                this.forgotForm.errors.email = r.response.data.errors.email[0]
              }
            }
            this.componentKey++
            throw r
          }
        })
      })
    },
  },
}
</script>

<style scoped></style>
